<template>
  <v-toolbar :collapse="collapse" elevation="1" :extension-height="height" dense color="search" :style="style">
    <v-toolbar-title>
      {{ $t("common.labels.filter")}}
    </v-toolbar-title>

    <v-spacer v-if="!collapse"></v-spacer>

    <v-btn text @click="a()">
      <v-icon>{{ collapse ? "mdi-magnify" : "mdi-close" }}</v-icon>
    </v-btn>

    <template slot="extension">
      <v-row v-if="!hasSlotData">
        <v-col sm="7" md="9" lg="9" xl="10" cols="10">
          <v-text-field
            dense
            v-model="query"
            :placeholder="$t('search.teaser.form.query')"
            @keyup.native.enter="doSearch()"
            @keyup.native.esc="cancelSearch()"
          ></v-text-field>
        </v-col>
        <v-col sm="5" md="3" lg="3" xl="2" cols="2">
          <v-btn @click="doSearch()" block color="secondary" class="hidden-xs-only text--black">
            {{ $t("search.teaser.form.submit") }}</v-btn>
          <v-btn @click="doSearch()" block color="secondary" class="hidden-sm-and-up" icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <slot />
    </template>
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";
import { getColor } from "@/services/theme";
import { formatMixin, formMixin } from "@/helpers/mixins";

export default {
  name: "searchpanel",

  mixins: [formatMixin, formMixin],

  data() {
    return {
      collapse: true,
      query: "",
    };
  },

  props: {
    tabIndex: {
      type: Number,
      default: () => -1,
    },
    onAction: {
      type: Function,
    },
    height: {
      type: Number,
      default: () => 48,
    },
    autoUpdate: {
      type: Boolean,
      default: () => false,
    },
  },

  computed: {
    ...mapGetters(["profile"]),
    hasSlotData: function() {
      return this.$slots.default;
    },
    style: function() {
      return `background: ${getColor("navbg")}; background: linear-gradient(0deg, ${getColor("navbgtop")} 10%, ${getColor("navbg")} 75%;`;
    },
  },

  mounted() {
    if (this.profile.keepFiltersOpen) {
      this.collapse = false;
    }
  },

  watch: {
    query: function(val) {
      if (this.autoUpdate) {
        this.onAction(val);
      }
    },
  },

  methods: {
    a: function() {
      this.collapse = !this.collapse;
    },
    doSearch: function() {
      this.onAction(this.query);
    },
    cancelSearch: function() {
      this.query = ""; 
      this.doSearch();
    },
  },
};
</script>
