<template>
  <v-container fluid grid-list-lg class="pa-0">
    <SearchHeader :onAction="doSearch"></SearchHeader>

    <CfgPanel ref="cfgpanel" :onSave="save" :title="$t('projectPhases.edit.title')" :tabIndex="7003">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="projectType.name"
                :rules="rules.required"
                :label="$t('projectPhases.list.name')"
                required
                ref="firstfield"
                :tabIndex="7002"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <small>{{ $t("common.form.requiredText") }}</small>
            </v-col>
            <v-col cols="12">
              <v-alert text icon="mdi-help" border="left" color="green" block>
                {{ $t("projectPhases.edit.help") }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </CfgPanel>

    <v-data-table
      :search="search"
      :headers="headers"
      :items="projectPhases"
      item-key="id"
      :show-select="false"
      :disable-pagination="true"
      :hide-default-footer="true"
      class="page__table elevation-1 ma-5"
    >
      <template v-slot:body="props">
        <draggable :list="props.items" tag="tbody" @end="endDrag(props.items)">
          <tr v-for="(item, index) in props.items" :key="index">
            <td width="30px">
              <v-icon small class="page__grab-icon"> mdi-arrow-all </v-icon>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.sortweight }}</td>
            <td>
              <v-icon @click="editItem(item)"> mdi-account-edit </v-icon>
              <v-icon @click="deleteItem(item)"> mdi-account-remove </v-icon>
            </td>
          </tr>
        </draggable>
      </template>
      <template v-slot:no-data>
        {{ $t("common.list.noResults") }}
      </template>
    </v-data-table>

  </v-container>
</template>

<script>
import axios from "axios";
import { formatMixin, listMixin } from "@/helpers/mixins";

import Draggable from "vuedraggable";

import CfgPanel from "@/components/_common/cfgpanel";
import SearchHeader from "@/components/_common/searchheader";

export default {
  mixins: [formatMixin, listMixin],

  components: {
    CfgPanel,
    SearchHeader,
    Draggable,
  },

  data() {
    return {
      valid: false,
      show1: false,
      search: "",
      headers: [
        { text: "", align: "left", value: "name" },
        { text: this.$i18n.t("projectPhases.list.name"), align: "left", value: "name"},
        { text: "SORT", align: "left", value: "sortweight" },
        { text: this.$i18n.t("common.list.actions"), value: "action", sortable: false },
      ],
      projectPhases: [],
      sortedProjectPhases: [],
      editedIndex: -1,
      projectType: {},
      rules: {
        required: [(v) => !!v || this.$i18n.t("common.form.required")],
        email: [(v) => !!v || this.$i18n.t("common.form.requiredEmail"), (v) => /.+@.+\..+/.test(v) || this.$i18n.t("common.form.validEmail")],
        pwdvalidate: [
          (v) => !!v || this.$i18n.t("common.form.requiredEmail"),
          (v) => v == this.projectType.password || this.$i18n.t("common.form.identicalEmail"),
        ],
      },
    };
  },

  created() {
    this.loadprojectPhases();
  },

  methods: {
    doSearch: function (val) {
      this.search = val;
    },

    loadprojectPhases: function () {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/projectphases`)
        .then((response) => {
          this.projectPhases = response.data;
          this.sortedProjectPhases = this.projectPhases.map((p) => p.id);
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("loading", false);
        });
    },

    reset: function () {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },

    editItem: function (item) {
      this.$refs.cfgpanel.show();
      this.$refs.firstfield.focus();
      var u = null;
      this.editedIndex = -1;
      this.projectType = {};

      if (item) {
        u = this.projectPhases.find((projectType) => {
          return projectType.id === item.id;
        });
        this.editedIndex = this.projectPhases.indexOf(u);
        if (this.editedIndex >= 0) {
          this.projectType = Object.assign({}, u);
        }
      }
    },

    deleteItem: function (item) {
      if (confirm("Are you sure you want to delete this Product type?")) {
        axios
          .delete(`/api/projectphases/${item.id}`)
          .then(() => {
            this.loadprojectPhases();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    endDrag: function (lst) {
      this.sortedProjectPhases = lst.map((p) => p.id);
      axios
        .post(`/api/projectphases/savesortorder`, { data: this.sortedProjectPhases })
        .then(() => {
          this.loadprojectPhases();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    save: function () {
      if (this.$refs.form.validate()) {
        let u = { ...this.projectType };

        axios
          .post(`/api/projectphases/${u.id || ""}`, u)
          .then((msg) => {
            this.updateItemList(this.projectPhases, msg.data);
            this.$refs.cfgpanel.hide();
          })
          .catch((e) => {
            console.log(e);
            this.$store.dispatch("showSnackMessage", { message: e });
          });
      }
    },
  },
};
</script>

<style lang="scss">
.page--table {
  .page {
    &__table {
      margin-top: 20px;
    }
    &__grab-icon {
      cursor: move;
    }
  }
}
</style>
