<template>
  <v-navigation-drawer v-model="drawer" fixed temporary right width="75%">
    <v-toolbar dark size="48">
      <v-btn icon dark @click="hide()" :tabIndex="tabIndex + 1">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn color="lime" text @click="save()" :tabIndex="tabIndex">{{ $t("common.labels.save") }}</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <slot />
    </v-card-text>
  </v-navigation-drawer>
</template>

<script>
import { formatMixin, formMixin } from "@/helpers/mixins";

export default {
  mixins: [formatMixin, formMixin],

  data() {
    return {
      drawer: null,
    };
  },

  props: {
    title: {
      type: String,
      default: () => {},
    },
    onSave: {
      type: Function,
    },
    tabIndex: {
      type: Number,
      default: () => -1,
    },
  },

  mounted() {
    let self = this;
    window.addEventListener("keyup", function(event) {
      if (event.keyCode === 27) {
        self.hide();
      }
    });
  },

  methods: {
    show: function() {
      this.drawer = true;
    },
    hide: function() {
      this.drawer = false;
    },
    toggle: function() {
      this.drawer = !this.drawer;
    },
    save: function() {
      this.onSave(this.data);
    },
    reset: function() {},
  },
};
</script>
