<template>
  <v-btn dark class="ml-10" @click="clicked" color="primary"> <v-icon left>mdi-plus</v-icon> {{ $t("common.labels.add") }} </v-btn>
  <!--:style="buttonStyle"-->
</template>

<script>
import { getColor } from "@/services/theme";
import { formMixin } from "@/helpers/mixins";

export default {
  name: "addBtn",
  mixins: [formMixin],
  data: () => ({
    fab: false,
    hidden: false,
    tabs: null,
  }),

  props: {
    onClick: {
      type: Function,
    },
    onClickKey: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    buttonStyle: function() {
      return `background-color: ${getColor("primary")}`;
    },
  },
  methods: {
    clicked: function() {
      this.scrollToTop();
      this.onClick(this.onClickKey)
    },
  },
};
</script>

<style lang="scss" scoped></style>
